<template>
  <div class="errors-not-found">
    <div class="errors-not-found__title">
      {{ $t("errors.notFound.title") }}
    </div>
    <div class="errors-not-found__text px-10">
      <span>
        {{ $t("errors.notFound.text") }}
      </span>
    </div>

    <div class="text--center">
      <va-button :to="{ name: 'admin' }">
        {{ $t("errors.notFound.back_button") }}
      </va-button>
    </div>
  </div>
</template>

<style lang="scss">
.errors-not-found {
  &__title {
    font-size: 3rem;
    line-height: 1.25em;
    font-weight: 500;
  }

  &__text {
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
  }
}
</style>
